import Vue from 'vue'
import Component from 'vue-class-component'
import { $APPNEXUS, $BEESWAX, $DBM, $KAYZEN, $MEDIAMATH, $META, $THETRADEDESK, $YOUTUBE } from '../../config/dspConfig'
import { DspInstruction, InstructionDsp, InstructionStrat } from '../../types/instruction_type'

/**
 * contain element and function used in form component (common rules, error-message and digit-mask)
 */
@Component({})
export class stratMixin extends Vue {
  get lineItemLabel (): string {
    let dsp: InstructionDsp = this.$route.params.dsp as InstructionDsp
    if ([$MEDIAMATH].includes(dsp)) {
      return 'Strategy'
    } else if ([$DBM, $APPNEXUS, $BEESWAX, $YOUTUBE].includes(dsp)) {
      return 'Line item'
    } else if ([$THETRADEDESK].includes(dsp)) {
      return 'Adgroup'
    } else if ([$KAYZEN].includes(dsp)) {
      return 'Campaign'
    } else if ([$META].includes(dsp)) {
      return 'Adset'
    } else {
      console.warn(`Dsp ${dsp} is not setted in 'lineItemLabel'`)
      return 'Line item'
    }
  }
  get lineItemField (): StringKeys<InstructionStrat> | NumberKeys<InstructionStrat> {
    let dsp: InstructionDsp = this.$route.params.dsp as InstructionDsp
    if ([$MEDIAMATH].includes(dsp)) {
      return 'strategy_id'
    } else if ([$DBM, $APPNEXUS, $BEESWAX, $YOUTUBE].includes(dsp)) {
      return 'line_item_id'
    } else if ([$THETRADEDESK].includes(dsp)) {
      return 'ad_group_id'
    } else if ([$KAYZEN].includes(dsp)) {
      return 'campaign_id'
    } else if ([$META].includes(dsp)) {
      return 'adset_id'
    } else {
      console.warn(`Dsp ${dsp} is not setted in 'lineItemField'`)
      return 'line_item_id'
    }
  }
  get unicityKey (): string {
    let dsp = this.$route.params.dsp

    if (dsp === $APPNEXUS) {
      return 'campaign_id'
    }

    return this.lineItemField
  }
}
